<template>
  <div class="login-container">
    <div class="login-block">
      <span class="login-title">单株碳汇管理后台</span>
      <el-input
        size="medium"
        placeholder="请输入账号"
        v-model="formData.username"
        >账号</el-input
      >
      <el-input
        size="medium"
        placeholder="请输入密码"
        v-model="formData.password"
        style="margin-top: 20px"
        type="password"
        >密码</el-input
      >
      <el-button
        size="medium"
        type="primary"
        style="width: 200px; margin-top: 30px"
        @click="formLogin"
        :loading="loading"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { myError } from '../../hooks/useMessage'
import { login } from '../../api'
import router from '../../router'
import { setToken } from '../../utils/auth'

const formData = reactive({
  username: '',
  password: ''
})

const loading = ref(false)

const formLogin = () => {
  if (!formData.username || !formData.password) {
    myError('请填写账号密码')
  } else {
    loading.value = true
    login(formData)
      .then((res) => {
        loading.value = false
        if (res.code === 1000) {
          setToken(res.data)
          router.push({
            path: '/'
          })
        } else {
          myError(res.error)
        }
      })
      .catch(() => {
        loading.value = false
      })
  }
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #9ee8e0, #accbea, #a9acf3);
  .login-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 300px;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: 4px 4px 2px #87829a, -4px -4px 2px #b8d3d0;
    .login-title {
      font-size: 20px;
      color: #ffffff;
      margin-bottom: 30px;
    }
  }
}
</style>
